import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"

export default class BlogList extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const { currentPage, totalPage } = this.props.pageContext

    const isFirst = currentPage === 1
    const isLast = currentPage === totalPage
    const newer = isFirst ? null : "/blog/p/" + (currentPage - 1).toString()
    const older = currentPage === totalPage ? null : "/blog/p/" + (currentPage + 1).toString()
    return (
      <Layout>
        <div className="content">
          <div className="post-list">
            {posts.map(({ node }) => {
              const title = node.frontmatter.title
              const thumnail = node.frontmatter.thumnail
              return (
                <div key={title} className="post-item">
                  <div className="post-stacked">
                    {null != thumnail ? <div className="post-thumbnail"><img alt={thumnail.name} src={thumnail.publicURL}></img></div> : ''}

                    <div className="post-title">{title}</div>
                    <div className="post-info">
                      Posted on {node.frontmatter.date}
                    </div>
                    <div className="post-content">
                      <div>{node.excerpt}</div>
                      <Link className="readmore" to={node.frontmatter.path}> ReadMore++</Link>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="blog-pagging">
            {!isFirst && (
              <div className="newer">
                <Link to={newer} rel="newer" >← newer Page</Link>
              </div>
            )}
            {!isLast && (
              <div className="older"><Link to={older} rel="older" >older Page →</Link>

              </div>
            )}
          </div>

        </div >
      </Layout>
    )
  }
}
export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            date(formatString: "DD MMMM YYYY")
            title
            description
            path
            thumnail {
              id
              publicURL
              name
            }
          }
        }
      }
    }
  }
`